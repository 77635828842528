// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { ExpertHelpCtaCoachFragmentDoc } from '../homepage/__generated-gql-types__/ExpertHelpCta.generated';
import { TestimonialsReviewFragmentDoc } from '../common/__generated-gql-types__/Testimonials.generated';
import {
  UpcomingEventsEventFragmentDoc,
  UpcomingEventsClassFragmentDoc,
} from '../homepage/__generated-gql-types__/UpcomingEvents.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewHomepageCoachFragment = {
  __typename?: 'Coach';
  id: string;
  slug: string;
  user: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
  };
  categoryInformationList?: Array<{
    __typename?: 'CategoryInformation';
    category: { __typename?: 'Category'; id: string; name: string };
  }> | null;
};

export type NewHomepageReviewFragment = {
  __typename?: 'Review';
  id: string;
  reviewedAt: number;
  comment?: string | null;
  outcomes?: Array<
    | {
        __typename?: 'JobOfferInfo';
        company: {
          __typename?: 'Company';
          id?: string | null;
          name: string;
          logoLink?: string | null;
        };
        jobTitle?: { __typename?: 'JobTitle'; id: string; name: string } | null;
      }
    | {
        __typename?: 'SchoolAdmissionInfo';
        status?: Types.SchoolAdmissionStatus | null;
        school: {
          __typename?: 'School';
          id: string;
          name: string;
          logoLink?: string | null;
        };
      }
    | { __typename?: 'TestScoreV2'; score: number; testName: string }
  > | null;
  coach: {
    __typename?: 'Coach';
    slug: string;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
  };
  applicant?: {
    __typename?: 'Applicant';
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
  } | null;
};

export type NewHomepageEventFragment = {
  __typename?: 'Shindig';
  imageLink?: string | null;
  date: number;
  link: string;
  shindigId: string;
  shindigName: string;
  coaches?: Array<{
    __typename?: 'Coach';
    id: string;
    slug: string;
    reviewTotal: number;
    averageReviewRate: number;
    categoryInformationList?: Array<{
      __typename?: 'CategoryInformation';
      category: { __typename?: 'Category'; id: string; slug: string };
    }> | null;
    user: {
      __typename?: 'User';
      pictureLink?: string | null;
      firstName: string;
      lastName: string;
    };
  }> | null;
  categories?: Array<{
    __typename?: 'CategorySelection';
    category: { __typename?: 'Category'; id: string; slug: string };
  }> | null;
};

export type NewHomepageClassFragment = {
  __typename?: 'Class';
  imageLink?: string | null;
  slug?: string | null;
  price?: number | null;
  durationInMinute?: number | null;
  maximumAttendees?: number | null;
  isUserOnDiscoveryList?: boolean | null;
  isFreeEvent?: boolean | null;
  classId: string;
  className?: string | null;
  upcomingSessionsV2?: Array<{
    __typename?: 'CoachingSession';
    id: string;
    startAt: number;
    totalAttendees: number;
    userIsEnrolled?: boolean | null;
  }> | null;
  category?: { __typename?: 'Category'; id: string; slug: string } | null;
  coach?: {
    __typename?: 'Coach';
    id: string;
    slug: string;
    averageReviewRate: number;
    reviewTotal: number;
    user: {
      __typename?: 'User';
      firstName: string;
      lastName: string;
      pictureLink?: string | null;
    };
    coachingRelationship?: {
      __typename?: 'CoachingRelationship';
      id: string;
      blockedByCoach: boolean;
    } | null;
    categoryInformationList?: Array<{
      __typename?: 'CategoryInformation';
      category: { __typename?: 'Category'; id: string; slug: string };
    }> | null;
  } | null;
};

export type NewHomepageDataQueryVariables = Types.Exact<{
  shindigStart: Types.Scalars['Long']['input'];
}>;

export type NewHomepageDataQuery = {
  __typename?: 'Query';
  searchClassesAndShindigs: {
    __typename?: 'ClassShindigBatchResponse';
    results: Array<
      | {
          __typename?: 'Class';
          imageLink?: string | null;
          slug?: string | null;
          price?: number | null;
          durationInMinute?: number | null;
          maximumAttendees?: number | null;
          isUserOnDiscoveryList?: boolean | null;
          isFreeEvent?: boolean | null;
          classId: string;
          className?: string | null;
          upcomingSessionsV2?: Array<{
            __typename?: 'CoachingSession';
            id: string;
            startAt: number;
            totalAttendees: number;
            userIsEnrolled?: boolean | null;
          }> | null;
          category?: {
            __typename?: 'Category';
            id: string;
            slug: string;
          } | null;
          coach?: {
            __typename?: 'Coach';
            id: string;
            slug: string;
            averageReviewRate: number;
            reviewTotal: number;
            user: {
              __typename?: 'User';
              firstName: string;
              lastName: string;
              pictureLink?: string | null;
            };
            coachingRelationship?: {
              __typename?: 'CoachingRelationship';
              id: string;
              blockedByCoach: boolean;
            } | null;
            categoryInformationList?: Array<{
              __typename?: 'CategoryInformation';
              category: { __typename?: 'Category'; id: string; slug: string };
            }> | null;
          } | null;
        }
      | {
          __typename?: 'Shindig';
          imageLink?: string | null;
          date: number;
          link: string;
          shindigId: string;
          shindigName: string;
          coaches?: Array<{
            __typename?: 'Coach';
            id: string;
            slug: string;
            reviewTotal: number;
            averageReviewRate: number;
            categoryInformationList?: Array<{
              __typename?: 'CategoryInformation';
              category: { __typename?: 'Category'; id: string; slug: string };
            }> | null;
            user: {
              __typename?: 'User';
              pictureLink?: string | null;
              firstName: string;
              lastName: string;
            };
          }> | null;
          categories?: Array<{
            __typename?: 'CategorySelection';
            category: { __typename?: 'Category'; id: string; slug: string };
          }> | null;
        }
    >;
  };
};

export const NewHomepageCoachFragmentDoc = gql`
  fragment NewHomepage_Coach on Coach {
    ...ExpertHelpCta_Coach
  }
  ${ExpertHelpCtaCoachFragmentDoc}
`;
export const NewHomepageReviewFragmentDoc = gql`
  fragment NewHomepage_Review on Review {
    ...Testimonials_Review
  }
  ${TestimonialsReviewFragmentDoc}
`;
export const NewHomepageEventFragmentDoc = gql`
  fragment NewHomepage_Event on Shindig {
    ...UpcomingEvents_Event
  }
  ${UpcomingEventsEventFragmentDoc}
`;
export const NewHomepageClassFragmentDoc = gql`
  fragment NewHomepage_Class on Class {
    ...UpcomingEvents_Class
  }
  ${UpcomingEventsClassFragmentDoc}
`;
export const NewHomepageDataDocument = gql`
  query NewHomepage_Data($shindigStart: Long!) {
    searchClassesAndShindigs(
      timeFilter: UPCOMING
      start: $shindigStart
      count: 20
    ) {
      results {
        ...NewHomepage_Event
        ...NewHomepage_Class
      }
    }
  }
  ${NewHomepageEventFragmentDoc}
  ${NewHomepageClassFragmentDoc}
`;

/**
 * __useNewHomepageDataQuery__
 *
 * To run a query within a React component, call `useNewHomepageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHomepageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHomepageDataQuery({
 *   variables: {
 *      shindigStart: // value for 'shindigStart'
 *   },
 * });
 */
export function useNewHomepageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    NewHomepageDataQuery,
    NewHomepageDataQueryVariables
  > &
    (
      | { variables: NewHomepageDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewHomepageDataQuery, NewHomepageDataQueryVariables>(
    NewHomepageDataDocument,
    options,
  );
}
export function useNewHomepageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewHomepageDataQuery,
    NewHomepageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewHomepageDataQuery,
    NewHomepageDataQueryVariables
  >(NewHomepageDataDocument, options);
}
export function useNewHomepageDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NewHomepageDataQuery,
    NewHomepageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NewHomepageDataQuery,
    NewHomepageDataQueryVariables
  >(NewHomepageDataDocument, options);
}
export type NewHomepageDataQueryHookResult = ReturnType<
  typeof useNewHomepageDataQuery
>;
export type NewHomepageDataLazyQueryHookResult = ReturnType<
  typeof useNewHomepageDataLazyQuery
>;
export type NewHomepageDataSuspenseQueryHookResult = ReturnType<
  typeof useNewHomepageDataSuspenseQuery
>;
export type NewHomepageDataQueryResult = Apollo.QueryResult<
  NewHomepageDataQuery,
  NewHomepageDataQueryVariables
>;
