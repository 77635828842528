import {
  Breakpoints,
  Carousel,
  compact,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import { type DocumentNode } from 'graphql';
import { type FC } from 'react';

import mxpnl from '../../utils/analytics/mixpanel';
import { addUTMToEventLink } from '../../utils/events';
import ClassCard from '../common/ClassCard';
import { EventCard } from '../common/EventCard';

import {
  type UpcomingEventsClassFragment,
  type UpcomingEventsEventFragment,
} from './__generated-gql-types__/UpcomingEvents.generated';

interface UpcomingEventsProps {
  events: Array<UpcomingEventsEventFragment | UpcomingEventsClassFragment>;
  trackingPrefix: string;
  refetchQueries?: DocumentNode[];
}

export const UpcomingEvents: FC<UpcomingEventsProps> = ({
  events,
  trackingPrefix,
  refetchQueries,
}) => {
  const isMd = useMediaQuery(Breakpoints.MD);
  const isSm = useMediaQuery(Breakpoints.SM);

  return (
    <section className="mx-auto w-full max-w-7xl pt-16 sm:px-6 sm:pt-32">
      <h2 className="px-6 pb-7 font-condensed text-4xl sm:px-0">
        Browse upcoming free events
      </h2>
      <Carousel
        disableArrowGradient
        swiperProps={{
          slidesPerView: isMd ? 4 : isSm ? 3 : 1.3,
          spaceBetween: 16,
          slidesPerGroup: isMd ? 4 : isSm ? 3 : 1,
          className: isSm ? '' : 'px-6',
        }}
        items={events
          .map((event, index) => {
            if (event.__typename === 'Class' && event.isFreeEvent) {
              const session = event.upcomingSessionsV2?.[0];
              return (
                <ClassCard
                  key={event.classId}
                  classInfo={event}
                  classSession={session}
                  onClick={() => {
                    mxpnl.track('EventsPage - click - Class', {
                      index,
                      classId: event.classId,
                      classSlug: event.slug,
                      session,
                    });
                  }}
                  refetchQueries={refetchQueries}
                  trackingPrefix={`${trackingPrefix} - UpcomingEvents`}
                />
              );
            }
            if (event.__typename === 'Shindig') {
              return (
                <div key={event.shindigId} className="h-full sm:w-full">
                  <EventCard
                    event={{
                      ...event,
                      link: addUTMToEventLink(event.link, 'homepage'),
                    }}
                  />
                </div>
              );
            }
          })
          .filter(compact)}
      />
    </section>
  );
};
