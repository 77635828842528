import { formatName, ProfileImage } from '@leland-dev/leland-ui-library';
import { type StaticImageData } from 'next/image';
import { type FC } from 'react';

import { getCoachProfileUrl } from '../../utils/routing';
import ImageWithBlur from '../image/ImageWithBlur';

import { type CoachCardCoachFragment } from './__generated-gql-types__/CoachCard.generated';

export interface Institution {
  title: string;
  sub: string;
  image: StaticImageData;
}

export interface Coach {
  slug: string;
  institutions: [Institution, Institution];
}

const formatCoachCategories = (
  categories: CoachCardCoachFragment['categoryInformationList'],
): string => {
  if (!categories?.length) {
    return '';
  }

  const categoryNames = categories.map(({ category }) => category.name);
  const lastCategory = categoryNames.pop();
  return `${
    categoryNames.length ? `${categoryNames.join(', ')} and ` : ''
  }${lastCategory}`;
};

export const CoachCard: FC<{
  coach: Optional<CoachCardCoachFragment>;
  institutions: Coach['institutions'];
  innerRef?: (el: HTMLAnchorElement) => void;
}> = ({ coach, institutions, innerRef }) =>
  coach ? (
    <a
      href={getCoachProfileUrl(coach.slug)}
      ref={innerRef}
      className={`relative flex w-full flex-col items-center justify-between rounded-xl border border-leland-gray-stroke bg-white shadow-md transition-all duration-150 hover:-translate-y-2 hover:shadow-lg`}
    >
      <div className="flex h-52 w-full flex-col items-center border-b border-leland-gray-stroke p-5 text-center">
        {coach.user.pictureLink ? (
          <ProfileImage className="mb-4 size-20" {...coach.user} />
        ) : null}

        <p className="text-xl font-medium text-leland-gray-dark">
          {formatName(coach.user)}
        </p>
        <p className="line-clamp-2 text-leland-gray-light">
          {formatCoachCategories(coach.categoryInformationList)} Coach
        </p>
      </div>

      <div className="flex min-h-0 w-full flex-1 flex-col space-y-5 p-5">
        {institutions.map((institution, index) => (
          <div key={index} className="flex items-center">
            <div className="relative size-10">
              <ImageWithBlur
                className="size-10 rounded object-cover"
                src={institution.image}
                sizes={{ dflt: '2.5rem' }}
                alt={institution.title}
                fill
              />
            </div>
            <div className="pl-3">
              <p className="line-clamp-1 font-medium text-leland-gray-dark">
                {institution.title}
              </p>
              <p className="line-clamp-1 text-sm text-leland-gray-light">
                {institution.sub}
              </p>
            </div>
          </div>
        ))}
      </div>
    </a>
  ) : null;
