import bain from '../assets/images/bain.png';
import carmax from '../assets/images/carmax.png';
import duke from '../assets/images/duke.png';
import facebook from '../assets/images/facebook.png';
import goldman from '../assets/images/goldman.png';
import harvardBIT from '../assets/images/harvard-bio-investment-team.jpg';
import harvardLaw from '../assets/images/harvard-law.jpg';
import harvard from '../assets/images/harvard.png';
import hbs from '../assets/images/hbs.png';
import jpMorgan from '../assets/images/jpmorgan.png';
import mckinseyCo from '../assets/images/mckinsey_co.jpg';
import menloCoaching from '../assets/images/menlocoaching.png';
import queensDefenders from '../assets/images/queensdefenders.png';
import quintPub from '../assets/images/quintpub.png';
import salesforce from '../assets/images/salesforce.png';
import stacyBlackman from '../assets/images/stacyblackman.png';
import stanfordGsb from '../assets/images/stanford-gsb.jpg';
import stanfordMedical from '../assets/images/stanford-medical.png';
import stanford from '../assets/images/stanford.png';
import stripe from '../assets/images/stripe.png';
import uTexas from '../assets/images/u-texas.png';
import ucla from '../assets/images/ucla.png';
import warburgPincus from '../assets/images/warburg-pincus.png';
import { type Coach } from '../components/common/CoachCard';
import { __DEV__, __STAGING__ } from '../utils/constants';

const stagingCoaches: Coach[] = [
  {
    slug: 'joe-tears',
    institutions: [
      {
        title: 'Facebook',
        sub: 'Product Manager',
        image: facebook,
      },
      {
        title: 'Harvard Business School',
        sub: 'MBA',
        image: hbs,
      },
    ],
  },
  {
    slug: 'jiashuo-wang',
    institutions: [
      {
        title: 'Stanford University',
        sub: 'BA, Psychology',
        image: stanford,
      },
      {
        title: 'UCLA',
        sub: 'PhD, Clinical Psychology',
        image: ucla,
      },
    ],
  },
  {
    slug: 'haasin-farooq',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'B.A., Statistics + Economics',
        image: harvard,
      },
      {
        title: 'Harvard Bio Investment Team',
        sub: 'Co-President and CEO',
        image: harvardBIT,
      },
    ],
  },
  {
    slug: 'tanner-thelin',
    institutions: [
      {
        title: 'Bain & Company',
        sub: 'Associate Consultant',
        image: bain,
      },
      {
        title: 'Duke University',
        sub: 'Bachelor’s Degree',
        image: duke,
      },
    ],
  },
  {
    slug: 'john-koelliker',
    institutions: [
      {
        title: 'Stacy Blackman Consulting',
        sub: 'Stacy Blackman Consulting',
        image: stacyBlackman,
      },
      {
        title: 'Menlo Coaching',
        sub: 'Senior Consultant',
        image: menloCoaching,
      },
    ],
  },
];

const prodCoaches: Coach[] = [
  {
    slug: 'lola-agabalogun',
    institutions: [
      {
        title: 'Stripe',
        sub: 'Strategy & Product',
        image: stripe,
      },
      {
        title: 'McKinsey & Company',
        sub: 'Business Analyst',
        image: mckinseyCo,
      },
    ],
  },
  {
    slug: 'debby-couture',
    institutions: [
      {
        title: 'Stanford University',
        sub: 'Director of Graduate Admissions',
        image: stanford,
      },
      {
        title: 'University of Texas Austin',
        sub: 'Sr. Strategic Planning Associate',
        image: uTexas,
      },
    ],
  },
  {
    slug: 'alice-li-6ca99619',
    institutions: [
      {
        title: 'Harvard School of Dental Medicine',
        sub: 'DMD, Dentistry',
        image: harvard,
      },
      {
        title: 'Quintessence Publishing USA',
        sub: 'Dental Illustrator',
        image: quintPub,
      },
    ],
  },
  {
    slug: 'karla-maganafigueroa',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'Master’s in Public Policy',
        image: harvard,
      },
      {
        title: 'J.P. Morgan',
        sub: 'Investment Banking Associate',
        image: jpMorgan,
      },
    ],
  },
  {
    slug: 'gaby-joseph',
    institutions: [
      {
        title: 'Stanford Medical School',
        sub: 'M.D.',
        image: stanfordMedical,
      },
      {
        title: 'Stanford G.S.B.',
        sub: 'MBA',
        image: stanfordGsb,
      },
    ],
  },
  {
    slug: 'anna-gee',
    institutions: [
      {
        title: 'Harvard Law School',
        sub: 'J.D.',
        image: harvardLaw,
      },
      {
        title: 'Queens Defenders',
        sub: 'Staff Attorney',
        image: queensDefenders,
      },
    ],
  },
  {
    slug: 'sreya-maram',
    institutions: [
      {
        title: 'Salesforce',
        sub: 'Associate Product Manager',
        image: salesforce,
      },
      {
        title: 'CarMax',
        sub: 'Associate Product Manager Intern',
        image: carmax,
      },
    ],
  },
  {
    slug: 'wil-eyi',
    institutions: [
      {
        title: 'Warburg Pincus',
        sub: 'Associate',
        image: warburgPincus,
      },
      {
        title: 'Goldman Sachs',
        sub: 'Investment Banking Analyst',
        image: goldman,
      },
    ],
  },
  {
    slug: 'neha-singh',
    institutions: [
      {
        title: 'Facebook',
        sub: 'Product Manager',
        image: facebook,
      },
      {
        title: 'Harvard Business School',
        sub: 'MBA',
        image: hbs,
      },
    ],
  },
  {
    slug: 'sachin-waikar',
    institutions: [
      {
        title: 'Stanford University',
        sub: 'BA, Psychology',
        image: stanford,
      },
      {
        title: 'UCLA',
        sub: 'PhD, Clinical Psychology',
        image: ucla,
      },
    ],
  },
  {
    slug: 'john-rho',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'B.A., Statistics + Economics',
        image: harvard,
      },
      {
        title: 'Harvard Bio Investment Team',
        sub: 'Co-President and CEO',
        image: harvardBIT,
      },
    ],
  },
  {
    slug: 'julia-mark',
    institutions: [
      {
        title: 'Bain & Company',
        sub: 'Associate Consultant',
        image: bain,
      },
      {
        title: 'Duke University',
        sub: 'Bachelor’s Degree',
        image: duke,
      },
    ],
  },
  {
    slug: 'andrew-pelletier',
    institutions: [
      {
        title: 'Stacy Blackman Consulting',
        sub: 'Stacy Blackman Consulting',
        image: stacyBlackman,
      },
      {
        title: 'Menlo Coaching',
        sub: 'Senior Consultant',
        image: menloCoaching,
      },
    ],
  },
];

export const FeaturedCoaches =
  __DEV__ || __STAGING__ ? stagingCoaches : prodCoaches;
