import Image from 'next/image';
import { type FC } from 'react';

import FirstImage from '../../assets/images/homepage/how-it-works/image-1.png';
import SecondImage from '../../assets/images/homepage/how-it-works/image-2.png';
import ThirdImage from '../../assets/images/homepage/how-it-works/image-3.png';
import { NUM_LIVE_COACHES_STR } from '../../utils/constants';

const HowItWorksSteps = [
  {
    title: 'Find your coach',
    description: `Browse ${NUM_LIVE_COACHES_STR} experts and schedule free intro calls to get to know each coach.`,
    image: FirstImage,
  },
  {
    title: 'Get custom-tailored coaching',
    description:
      'Your coach will help you make a concrete plan and work on each step with you.',
    image: SecondImage,
  },
  {
    title: 'Reach your goal',
    description:
      'Work with your coach to achieve your goal within your timeline.',
    image: ThirdImage,
  },
];

export const HowItWorks: FC = () => {
  return (
    <section className="mt-64 w-full items-center border-b border-leland-gray-stroke sm:mt-72 sm:px-6">
      <div className="mx-auto flex max-w-7xl flex-col">
        <header className="flex flex-col items-center space-y-2 text-center">
          <h2 className="text-base font-medium uppercase tracking-wider text-leland-gray-light">
            How it works
          </h2>
          <h3 className="max-w-sm font-condensed text-4xl font-medium">
            Achieving your goal is more doable than ever.
          </h3>
        </header>
        <div className="mt-20 flex flex-col gap-10 sm:mt-24 sm:flex-row sm:gap-0">
          {HowItWorksSteps.map((step) => {
            return (
              <div
                className="flex flex-col items-center border-b border-leland-gray-stroke px-8 text-center sm:border-b-0 sm:px-0"
                key={step.title}
              >
                <h2 className="text-xl font-medium">{step.title}</h2>
                <p className="mt-1 max-w-80 text-leland-gray-light">
                  {step.description}
                </p>
                <Image
                  className="mt-6"
                  src={step.image}
                  alt={step.title}
                  width={653}
                  height={716}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
