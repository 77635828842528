import {
  Breakpoints,
  ButtonSize,
  Carousel,
  compact,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import Link from 'next/link';
import { type FC } from 'react';

import { NUM_LIVE_COACHES_STR } from '../../utils/constants';
import { FeaturedCoaches } from '../../utils/featured-coaches';
import {
  ChecklistHero,
  type ChecklistItemPointProps,
} from '../common/ChecklistHero';
import { CoachCard } from '../common/CoachCard';

import { type ExpertHelpCtaCoachFragment } from './__generated-gql-types__/ExpertHelpCta.generated';

const ITEM_POINTS: ChecklistItemPointProps[] = [
  {
    title: 'Stick to your budget',
    description: 'Leland has coaches to fit virtually every budget.',
  },
  {
    title: 'Find the perfect mentor',
    description: `With ${NUM_LIVE_COACHES_STR} coaches across 50 categories, Leland has someone who can help you achieve your goal.`,
  },
  {
    title: 'Protect your purchase',
    description: (
      <span>
        With{' '}
        <Link
          className="underline underline-offset-2"
          href="/legal/experience-guarantee"
        >
          Leland&rsquo;s Experience Guarantee
        </Link>
        , you can feel confident in your coaching purchase.
      </span>
    ),
  },
];

interface ExpertHelpCtaProps {
  coaches: ExpertHelpCtaCoachFragment[];
}

export const ExpertHelpCta: FC<ExpertHelpCtaProps> = ({ coaches }) => {
  const isSm = useMediaQuery(Breakpoints.SM);
  const isLg = useMediaQuery(Breakpoints.LG);

  return (
    <ChecklistHero
      title="It&rsquo;s never been easier to get help from an expert."
      itemPoints={ITEM_POINTS}
    >
      <div className="relative top-60 -mt-80 max-w-7xl px-6 sm:top-80 sm:mx-auto">
        <Carousel
          swiperProps={{
            slidesPerView: isLg ? 4 : isSm ? 3 : 1.25,
            spaceBetween: 18,
            wrapperClass: 'mt-10',
            slidesPerGroup: isLg ? 4 : isSm ? 3 : 1,
          }}
          buttonSize={ButtonSize.MEDIUM}
          disableArrowGradient
          items={coaches
            .map((coach, index) => {
              const featuredCoach = FeaturedCoaches.find(
                (fCoach) => fCoach.slug === coach.slug,
              );
              return featuredCoach ? (
                <div className="w-full pb-2">
                  <CoachCard
                    key={index}
                    coach={coach}
                    institutions={featuredCoach.institutions}
                  />
                </div>
              ) : null;
            })
            .filter(compact)}
        />
      </div>
    </ChecklistHero>
  );
};
