import {
  Breakpoints,
  Carousel,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import Image, { type StaticImageData } from 'next/image';
import Link from 'next/link';
import { type FC } from 'react';

import { GoalName } from '../../__generated-gql-types__/globalTypes';
import CollegeCategoryImage from '../../assets/images/homepage/popular-categories-images/college.png';
import DentalSchoolCategoryImage from '../../assets/images/homepage/popular-categories-images/dental-school.png';
import GmatCategoryImage from '../../assets/images/homepage/popular-categories-images/gmat.png';
import GreCategoryCategoryImage from '../../assets/images/homepage/popular-categories-images/gre.png';
import InvestmentBankingCategoryImage from '../../assets/images/homepage/popular-categories-images/investment-banking.png';
import LawSchoolCategoryImage from '../../assets/images/homepage/popular-categories-images/law-school.png';
import ManagementConsultingCategoryImage from '../../assets/images/homepage/popular-categories-images/management-consulting.png';
import MastersProgramsCategoryImage from '../../assets/images/homepage/popular-categories-images/masters-programs.png';
import MbaCategoryImage from '../../assets/images/homepage/popular-categories-images/mba.png';
import MedicalSchoolCategoryImage from '../../assets/images/homepage/popular-categories-images/medical-school.png';
import PrivateEquityCategoryImage from '../../assets/images/homepage/popular-categories-images/private-equity.png';
import ProductManagementCategoryImage from '../../assets/images/homepage/popular-categories-images/product-management.png';
import { CATEGORY_SLUGS } from '../../utils/constants';
import { generateSearchPath } from '../../utils/routing';

interface PopularCategory {
  name: string;
  url: string;
  image: StaticImageData;
}

const POPULAR_CATEGORIES: PopularCategory[] = [
  {
    name: 'MBA',
    url: generateSearchPath({
      goal: GoalName.SCHOOL,
      categorySlug: CATEGORY_SLUGS.MBA,
      subCategorySlug: undefined,
    }),
    image: MbaCategoryImage,
  },
  {
    name: 'Management Consulting',
    url: generateSearchPath({
      goal: GoalName.CAREER,
      categorySlug: CATEGORY_SLUGS.ManagementConsulting,
      subCategorySlug: undefined,
    }),
    image: ManagementConsultingCategoryImage,
  },
  {
    name: 'GMAT',
    url: generateSearchPath({
      goal: GoalName.TEST,
      categorySlug: CATEGORY_SLUGS.GMAT,
      subCategorySlug: undefined,
    }),
    image: GmatCategoryImage,
  },
  {
    name: 'Medical School',
    url: generateSearchPath({
      goal: GoalName.SCHOOL,
      categorySlug: CATEGORY_SLUGS.MedicalSchool,
      subCategorySlug: undefined,
    }),
    image: MedicalSchoolCategoryImage,
  },
  {
    name: 'College',
    url: generateSearchPath({
      goal: GoalName.SCHOOL,
      categorySlug: CATEGORY_SLUGS.College,
      subCategorySlug: undefined,
    }),
    image: CollegeCategoryImage,
  },
  {
    name: 'Product Management',
    url: generateSearchPath({
      goal: GoalName.CAREER,
      categorySlug: CATEGORY_SLUGS.ProductManagement,
      subCategorySlug: undefined,
    }),
    image: ProductManagementCategoryImage,
  },
  {
    name: `Master's Programs`,
    url: generateSearchPath({
      goal: GoalName.SCHOOL,
      categorySlug: CATEGORY_SLUGS.MastersPrograms,
      subCategorySlug: undefined,
    }),
    image: MastersProgramsCategoryImage,
  },
  {
    name: 'Private Equity',
    url: generateSearchPath({
      goal: GoalName.CAREER,
      categorySlug: CATEGORY_SLUGS.PrivateEquity,
      subCategorySlug: undefined,
    }),
    image: PrivateEquityCategoryImage,
  },
  {
    name: 'Dental School',
    url: generateSearchPath({
      goal: GoalName.SCHOOL,
      categorySlug: CATEGORY_SLUGS.DentalSchool,
      subCategorySlug: undefined,
    }),
    image: DentalSchoolCategoryImage,
  },
  {
    name: 'GRE',
    url: generateSearchPath({
      goal: GoalName.TEST,
      categorySlug: CATEGORY_SLUGS.GRE,
      subCategorySlug: undefined,
    }),
    image: GreCategoryCategoryImage,
  },
  {
    name: 'Investment Banking',
    url: generateSearchPath({
      goal: GoalName.CAREER,
      categorySlug: CATEGORY_SLUGS.InvestmentBanking,
      subCategorySlug: undefined,
    }),
    image: InvestmentBankingCategoryImage,
  },
  {
    name: 'Law School',
    url: generateSearchPath({
      goal: GoalName.SCHOOL,
      categorySlug: CATEGORY_SLUGS.LawSchool,
      subCategorySlug: undefined,
    }),
    image: LawSchoolCategoryImage,
  },
];

const PopularCategoryCard: FC<PopularCategory> = ({ name, url, image }) => {
  return (
    <a className="group flex w-full flex-col" href={url}>
      <Image
        className="h-auto w-full transition-opacity duration-150 sm:group-hover:opacity-70"
        src={image}
        alt={`${name} Category Image`}
        width={548}
        height={300}
      />
      <span className="mt-4 transition-colors duration-150 sm:group-hover:text-leland-gray-light">
        {name}
      </span>
    </a>
  );
};

export const PopularCategories: FC = () => {
  const isSm = useMediaQuery(Breakpoints.SM);
  const isMd = useMediaQuery(Breakpoints.MD);

  return (
    <section className="mx-auto mt-16 w-full max-w-7xl sm:mt-20 sm:px-6">
      <div className="space-y-4 px-6 pb-7 sm:space-y-1 sm:px-0">
        <h2 className="font-condensed text-4xl">Popular Categories</h2>
        {isSm ? (
          <h3 className="text-xl text-leland-gray-light">
            Use Leland to{' '}
            <Link
              className="underline underline-offset-2"
              href={generateSearchPath({
                goal: GoalName.SCHOOL,
                categorySlug: undefined,
                subCategorySlug: undefined,
              })}
            >
              get into school
            </Link>
            ,{' '}
            <Link
              className="underline underline-offset-2"
              href={generateSearchPath({
                goal: GoalName.CAREER,
                categorySlug: undefined,
                subCategorySlug: undefined,
              })}
            >
              build your career
            </Link>
            ,{' '}
            <Link
              className="underline underline-offset-2"
              href={generateSearchPath({
                goal: GoalName.TEST,
                categorySlug: undefined,
                subCategorySlug: undefined,
              })}
            >
              or take a test
            </Link>
            .
          </h3>
        ) : (
          <h3 className="text-lg text-leland-gray-light sm:text-xl">
            <Link
              className="underline underline-offset-2"
              href={generateSearchPath({
                goal: GoalName.SCHOOL,
                categorySlug: undefined,
                subCategorySlug: undefined,
              })}
            >
              Get into school
            </Link>
            ,{' '}
            <Link
              className="underline underline-offset-2"
              href={generateSearchPath({
                goal: GoalName.CAREER,
                categorySlug: undefined,
                subCategorySlug: undefined,
              })}
            >
              build your career
            </Link>
            , or{' '}
            <Link
              className="underline underline-offset-2"
              href={generateSearchPath({
                goal: GoalName.TEST,
                categorySlug: undefined,
                subCategorySlug: undefined,
              })}
            >
              take a test
            </Link>
            .
          </h3>
        )}
      </div>
      <Carousel
        disableArrowGradient
        swiperProps={{
          slidesPerView: isMd ? 4 : isSm ? 3 : 1.3,
          spaceBetween: 16,
          slidesPerGroup: isMd ? 4 : isSm ? 3 : 1,
          className: isSm ? '' : 'px-6',
        }}
        items={POPULAR_CATEGORIES.map((category) => (
          <PopularCategoryCard key={category.name} {...category} />
        ))}
      />
    </section>
  );
};
