import { type FC } from 'react';

import { NUM_LIVE_COACHES_STR } from '../../utils/constants';
import { CategoryCta } from '../CategoryCta';

import { OutcomesMarquee } from './OutcomesMarquee';

export const Hero: FC = () => {
  return (
    <section className="mx-auto flex w-full flex-col justify-center text-center">
      <header className="space-y-5 px-6 sm:space-y-4 ">
        <h2 className="font-condensed text-5xl font-medium">
          Reach your most ambitious goals.
        </h2>
        <h3 className="text-xl text-leland-gray-light">
          Access coaching, courses, and content powered by{' '}
          {NUM_LIVE_COACHES_STR} career and admissions experts.
        </h3>
      </header>
      <div className="flex flex-col items-center justify-center px-6">
        <CategoryCta />
      </div>
      <p className="mt-20 px-6 text-sm uppercase tracking-wider text-leland-gray-light sm:mt-32">
        JOIN 100,000+ Real people achieving their goals on Leland
      </p>
      <div className="mt-6">
        <OutcomesMarquee />
      </div>
    </section>
  );
};
