import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@leland-dev/leland-ui-library';
import { type FC, useState } from 'react';

import { GoalName } from '../__generated-gql-types__/globalTypes';
import { useAuth } from '../context/AuthContext';
import { categorySlugToUrn } from '../utils/category';
import {
  APPLICANT_BASE_URL,
  APPLICANT_SIGNUP_URL,
  GMAT_CATEGORY_SLUG,
  MANAGEMENT_CONSULTING_CATEGORY_SLUG,
  MBA_CATEGORY_SLUG,
  MEDICAL_SCHOOL_CATEGORY_SLUG,
} from '../utils/constants';
import { generateSearchPath } from '../utils/routing';
import { getUrlString } from '../utils/url';

import { type CategoryCtaCategoryFragment } from './__generated-gql-types__/CategoryCta.generated';
import { type SelectOptionType } from './inputs/selects';
import Select from './inputs/selects/Select';
import { SingleCategoryTypeahead } from './typeahead/CategoryTypeahead';

const GOAL_OPTIONS: Array<SelectOptionType<GoalName>> = [
  { value: GoalName.SCHOOL, label: 'Get Into School' },
  { value: GoalName.CAREER, label: 'Build Your Career' },
  { value: GoalName.TEST, label: 'Take a Test' },
];

const POPULAR_CATEGORIES: CategoryCtaCategoryFragment[] = [
  {
    __typename: 'Category',
    slug: MBA_CATEGORY_SLUG,
    id: categorySlugToUrn(MBA_CATEGORY_SLUG),
    goal: GoalName.SCHOOL,
    name: 'MBA',
  },
  {
    __typename: 'Category',
    slug: MANAGEMENT_CONSULTING_CATEGORY_SLUG,
    id: categorySlugToUrn(MANAGEMENT_CONSULTING_CATEGORY_SLUG),
    goal: GoalName.CAREER,
    name: 'Management Consulting',
  },
  {
    __typename: 'Category',
    slug: GMAT_CATEGORY_SLUG,
    id: categorySlugToUrn(GMAT_CATEGORY_SLUG),
    goal: GoalName.TEST,
    name: 'GMAT',
  },
  {
    __typename: 'Category',
    slug: MEDICAL_SCHOOL_CATEGORY_SLUG,
    id: categorySlugToUrn(MEDICAL_SCHOOL_CATEGORY_SLUG),
    goal: GoalName.SCHOOL,
    name: 'Medical School',
  },
];

export const CategoryCta: FC = () => {
  const [selectedGoal, setSelectedGoal] = useState<Nullable<GoalName>>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<Nullable<CategoryCtaCategoryFragment>>(null);

  const { currentUser } = useAuth();

  const searchUrl = `${APPLICANT_BASE_URL}${generateSearchPath({ goal: selectedCategory?.goal, categorySlug: selectedCategory?.slug, subCategorySlug: null })}`;

  const searchPageURL = selectedCategory
    ? currentUser
      ? searchUrl
      : getUrlString(APPLICANT_SIGNUP_URL, {
          goal: selectedCategory.goal.toLowerCase(),
          category: selectedCategory.slug,
          redirect_url: searchUrl,
        })
    : null;

  return (
    <>
      <div className="mt-12 flex w-full flex-col space-y-2 rounded-xl border border-leland-gray-stroke p-2 shadow-md md:h-20 md:w-min md:flex-row md:items-center md:space-x-2 md:space-y-0">
        <Select
          className="h-16 rounded-lg md:h-full"
          label="Goal"
          labelIsHidden
          placeholder="Select your goal"
          onChange={(g) => {
            setSelectedCategory(null);
            if (g) {
              setSelectedGoal(g);
            }
          }}
          value={selectedGoal}
          options={GOAL_OPTIONS}
        />
        <div className="md:h-full md:py-2">
          <div className="h-px w-full bg-leland-gray-stroke md:h-full md:w-px" />
        </div>
        <SingleCategoryTypeahead
          className="h-16 md:h-full md:min-w-[16.25rem]"
          label="Category"
          labelIsHidden
          placeholder="Select a category"
          goal={selectedGoal}
          value={selectedCategory}
          onChange={(category) => {
            if (category) {
              setSelectedCategory(category);
            }
          }}
          disabled={!selectedGoal}
          required
          customErrorMessage="Category is required"
        />
        <a className="whitespace-nowrap" href={searchPageURL ?? ''}>
          <Button
            label="Find coaching"
            buttonColor={ButtonColor.PRIMARY}
            disabled={!searchPageURL}
            size={ButtonSize.LARGE}
            width={ButtonWidth.FULL_MOBILE}
          />
        </a>
      </div>
      <div className="mt-6 hidden items-center justify-start md:flex">
        <p className="mr-3 text-base font-medium text-leland-gray-dark">
          Popular:
        </p>
        <div className="grid auto-cols-min grid-flow-col gap-1.5 whitespace-nowrap">
          {POPULAR_CATEGORIES.map((category) => (
            <Button
              key={category.id}
              buttonColor={ButtonColor.GRAY}
              size={ButtonSize.SMALL}
              label={category.name}
              rounded
              onClick={() => {
                setSelectedGoal(category.goal);
                setSelectedCategory(category);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
