import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import { type GetStaticProps } from 'next';
import { type ComponentPropsWithoutRef } from 'react';

import type CoachesCarousel from '../components/CoachesCarousel';
import { getStaticCoachesCarouselData } from '../components/CoachesCarousel';
import {
  getNewHomepageStaticProps,
  NewHomepage,
} from '../components/NewHomepage';
import Layout, { getStaticLayoutQuery } from '../components/page/Layout';
import { initializeApollo } from '../lib/apolloClient';
import { APPLICANT_BASE_URL } from '../utils/constants';

const META_DESCRIPTION =
  'Leland is a marketplace for ambitious people to access the coaching, classes, and community they need to reach career and educational goals.';

type Props = Pick<
  ComponentPropsWithoutRef<typeof CoachesCarousel>,
  'coachesMap'
> &
  Pick<ComponentPropsWithoutRef<typeof Layout>, 'pageContentData'> &
  Pick<
    ComponentPropsWithoutRef<typeof NewHomepage>,
    'events' | 'testimonials' | 'contributors'
  >;

const Home: NextPageWithLayout<Props> = ({
  coachesMap,
  contributors,
  events,
  testimonials,
}) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Home');

  return (
    <NewHomepage
      coaches={Object.values(coachesMap)}
      contributors={contributors}
      events={events}
      testimonials={testimonials}
      trackingPrefix="Home"
    />
  );
};

Home.getLayout = (page, { pageContentData }) => {
  return (
    <Layout
      title="Find a Coach. Go Places."
      description={META_DESCRIPTION}
      canonicalLink={APPLICANT_BASE_URL}
      pageContentData={pageContentData}
    >
      {page}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const apolloClient = initializeApollo();

  const layoutCategoriesHeaderData = await getStaticLayoutQuery();
  const { events, testimonials, contributors } =
    await getNewHomepageStaticProps();

  return {
    props: {
      coachesMap: await getStaticCoachesCarouselData(apolloClient),
      contributors,
      pageContentData: layoutCategoriesHeaderData,
      events,
      testimonials,
    },
    revalidate: 86400,
  };
};

export default Home;
