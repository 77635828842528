import { isDateBefore } from '@leland-dev/leland-ui-library';
import { type ComponentPropsWithoutRef, type FC, useMemo } from 'react';

import { initializeApollo } from '../lib/apolloClient';
import { APPLICANT_SIGNUP_URL } from '../utils/constants';

import {
  type NewHomepageClassFragment,
  type NewHomepageCoachFragment,
  NewHomepageDataDocument,
  type NewHomepageDataQuery,
  type NewHomepageDataQueryVariables,
  type NewHomepageEventFragment,
  type NewHomepageReviewFragment,
} from './__generated-gql-types__/NewHomepage.generated';
import { ClosingCta } from './common/ClosingCta';
import {
  getTestimonialsStaticProps,
  Testimonials,
} from './common/Testimonials';
import { ExpertHelpCta } from './homepage/ExpertHelpCta';
import { Hero } from './homepage/Hero';
import { HowItWorks } from './homepage/HowItWorks';
import {
  getLelandPlusContributorsStaticProps,
  LelandPlus,
} from './homepage/LelandPlus';
import { PopularCategories } from './homepage/PopularCategories';
import { Press } from './homepage/Press';
import { UpcomingEvents } from './homepage/UpcomingEvents';

export type NewHomepageProps = {
  coaches: NewHomepageCoachFragment[];
  events: Array<NewHomepageEventFragment | NewHomepageClassFragment>;
  testimonials: NewHomepageReviewFragment[];
  trackingPrefix: string;
} & Pick<ComponentPropsWithoutRef<typeof LelandPlus>, 'contributors'>;

export const getNewHomepageStaticProps = async (): Promise<
  Omit<NewHomepageProps, 'coaches' | 'trackingPrefix'>
> => {
  const apolloClient = initializeApollo();
  const {
    data: { searchClassesAndShindigs: searchClassesAndShindigsData },
  } = await apolloClient.query<
    NewHomepageDataQuery,
    NewHomepageDataQueryVariables
  >({
    query: NewHomepageDataDocument,
    variables: {
      shindigStart: Date.now(),
    },
  });

  return {
    events: searchClassesAndShindigsData.results,
    testimonials: await getTestimonialsStaticProps(apolloClient),
    contributors: await getLelandPlusContributorsStaticProps(apolloClient),
  };
};

export const NewHomepage: FC<NewHomepageProps> = ({
  coaches,
  events: eventsProp,
  testimonials,
  contributors,
  trackingPrefix,
}) => {
  const events = useMemo(
    () =>
      eventsProp.filter((evt) => {
        if (evt.__typename === 'Class') {
          const session = evt.upcomingSessionsV2?.[0];
          return isDateBefore(Date.now(), session?.startAt ?? 0);
        }
        if (evt.__typename === 'Shindig') {
          return isDateBefore(Date.now(), evt.date);
        }
      }),
    [eventsProp],
  );

  return (
    <div className="flex flex-col items-center overflow-hidden pt-16 sm:pt-40">
      <div className="flex w-full flex-col">
        <Hero />
        <Press />
        <PopularCategories />
        <ExpertHelpCta coaches={coaches} />
        <HowItWorks />
        <UpcomingEvents
          events={events}
          trackingPrefix={`${trackingPrefix} - NewHomepage`}
          refetchQueries={[NewHomepageDataDocument]}
        />
        <LelandPlus contributors={contributors} />
      </div>
      <Testimonials testimonials={testimonials} />
      <ClosingCta buttonLinkHref={APPLICANT_SIGNUP_URL} />
    </div>
  );
};
